var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"sidebar-add-new-event","sidebar-class":"sidebar-lg","visible":_vm.isEventHandlerSidebarActive,"bg-variant":"white","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.$emit('update:is-event-handler-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.lang("t_addEvent"))+" ")]),_c('div',[(_vm.eventLocal.id)?_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"TrashIcon"},on:{"click":function($event){_vm.$emit('remove-event');
              hide();}}}):_vm._e(),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1)]),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"EventTitle","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"","label-for":"event-name"}},[_c('b-form-input',{attrs:{"id":"event-name","autofocus":"","state":_vm.getValidationState(validationContext),"trim":"","placeholder":_vm.lang('t_eventName')},model:{value:(_vm.eventLocal.title),callback:function ($$v) {_vm.$set(_vm.eventLocal, "title", $$v)},expression:"eventLocal.title"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"EventNote","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"","label-for":"eventNote","state":_vm.getValidationState(validationContext)},scopedSlots:_vm._u([{key:"option",fn:function(ref){
              var color = ref.color;
              var label = ref.label;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:("bg-" + color),staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}},{key:"selected-option",fn:function(ref){
              var color = ref.color;
              var label = ref.label;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:("bg-" + color),staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}}],null,true)},[_c('b-form-input',{attrs:{"id":"eventNote","autofocus":"","state":_vm.getValidationState(validationContext),"trim":"","placeholder":_vm.lang('t_eventNote')},model:{value:(_vm.eventLocal.title),callback:function ($$v) {_vm.$set(_vm.eventLocal, "title", $$v)},expression:"eventLocal.title"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"","label-for":"number","state":_vm.getValidationState(validationContext)},scopedSlots:_vm._u([{key:"option",fn:function(ref){
              var color = ref.color;
              var label = ref.label;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:("bg-" + color),staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}},{key:"selected-option",fn:function(ref){
              var color = ref.color;
              var label = ref.label;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:("bg-" + color),staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}}],null,true)},[_c('b-form-input',{attrs:{"id":"number","autofocus":"","state":_vm.getValidationState(validationContext),"trim":"","placeholder":_vm.lang('t_number')},model:{value:(_vm.eventLocal.title),callback:function ($$v) {_vm.$set(_vm.eventLocal, "title", $$v)},expression:"eventLocal.title"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"End Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"","label-for":"end-date","state":_vm.getValidationState(validationContext)}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ enableTime: true, dateFormat: 'Y-m-d H:i' },"placeholder":_vm.lang('t_eventDate')},model:{value:(_vm.eventLocal.end),callback:function ($$v) {_vm.$set(_vm.eventLocal, "end", $$v)},expression:"eventLocal.end"}})],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.lang("t_save"))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" "+_vm._s(_vm.lang("t_cancel"))+" ")])],1)],1)]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }