<template>
  <div
    class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1"
  >
    <div class="p-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        aria-controls="sidebar-add-new-event"
        :aria-expanded="String(isEventHandlerSidebarActive)"
        variant="outline-success"
        block
        @click="downloadExcel"
      >
        <i class="bi bi-download"></i>

        {{ lang("t_export") }}
      </b-button>
      <div class="mt-3">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">{{ lang("t_tags") }}</span>
        </h5>
        <!-- <b-form-checkbox v-model="checkAll" class="mb-1">
          View All
        </b-form-checkbox> -->
        <b-form-group>
          <b-form-checkbox-group
            @input="filterData()"
            v-model="selectedCalendars"
            name="event-filter"
          >
            <b-form-checkbox
              v-for="item in calendarOptions"
              :key="item.color"
              name="event-filter"
              :value="item.color"
              class="mb-1 d-block"
              :class="`custom-control-${item.color} `"
            >
              {{ item.label }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>
    <!-- <b-img :src="require('@/assets/images/pages/calendar-illustration.png')" /> -->
  </div>
</template>

<script>
import {
  BButton, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useCalendarSidebar from './useCalendarSidebar'

export default {
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
    }
  },
  methods: {
    downloadExcel() {
      // On Click Excel download button

      console.log("events", globalThis.calendar.events)
      let _data = [];
      for (const row of globalThis.calendar.events) {
        let obj = {};
        delete row._id;
        delete row.end;

        let _headers = {
          "start": globalThis._lang('t_date'),
          "finishcode": globalThis._lang('t_finishCode'),
          "color": globalThis._lang('t_color'),
          "status": globalThis._lang('t_status'),
          "contact_number": globalThis._lang('t_remoteNumber'),
          "note": globalThis._lang('t_note'),
        };

        for (const column of Object.keys(row)) {
          let key = _headers[column] == undefined ? column : _headers[column];
          obj[key] = row[column];
        }
        _data.push(obj);
      }

      var data = this.$xlsx.utils.json_to_sheet(_data);

      // A workbook is the name given to an Excel file
      var wb = this.$xlsx.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      this.$xlsx.utils.book_append_sheet(wb, data, `Calendar`); // sheetAName is name of Worksheet

      // export Excel file
      this.$xlsx.writeFile(wb, `Calendar_${new Date().getTime()}.xlsx`); // name of the file is 'book.xlsx'
    },

    xlsxToJson(data) {
      var jsonData = [];
      let headers = data[0];
      let _data = data.slice(1);
      let objectHolder = [];
      for (let row of _data) {
        let _row = [];
        for (var i = 0; i < row.length; i++) {
          _row.push([headers[i], row[i]]);
        }
        jsonData.push(Object.fromEntries(_row));
      }
      return jsonData;
    },


    filterData() {
      console.log('filterData', this.selectedCalendars)
      globalThis.calendar.getEvents(this.selectedCalendars);
    }
  },
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      require: true,
    },
  },
  mounted: function () {
    this.selectedCalendars = ['primary', 'danger', 'warning', 'info', 'success'];
  },
  setup() {
    const {
      calendarOptions,
      selectedCalendars,
      checkAll,
    } = useCalendarSidebar()

    return {
      calendarOptions,
      selectedCalendars,
      checkAll,
    }
  },
}
</script>

<style>
</style>
